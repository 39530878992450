﻿/* eslint-disable max-len */

const globalFormErrorMessages: FormValidatorMessages = {
    badInput: 'Please enter a number.',
    patternMismatch: 'Please match the requested format.',
    rangeOverflow: 'Please select a value that is no more than {max}.',
    rangeUnderflow: 'Please select a value that is no less than {min}.',
    stepMismatch: 'Please select a value that is a multiple from {step}.',
    tooLong:
        'Please shorten this text to no more than {maxLength} characters. You are currently using {length} characters.',
    tooShort:
        'Please lengthen this text to {minLength} characters or more. You are currently using {length} characters.',
    typeMismatch: 'The value you have entered for this field is invalid.',
    valueMissing: 'Please fill out this field.',
    // These properties must be empty.
    valid: '',
    customError: '',
};

const globalFormPasswordValidityMessages: FormExpandValidatorMessages = {
    passwordNotMatching: 'The password does not match the one above.',
};

const globalFormExpandErrorMessages: FormExpandValidatorMessages = {
    stepAdjust: 'The value you entered for this field is invalid. The value has been changed from {currentValue} to {newValue}',
    existigName: 'This name {currentValue} already exists.',
};

const globalBasketMessages: Translation = {
    messageEmptyBasket: { code: '1-6-6220', text: 'Shopping cart is empty' },
    messageAddedToBasket: { code: '1-6-6221', text: 'added to the shopping cart' },
    quickOrderMessageTooShort: { code: '1-6-6222', text: 'At least %1 characters are required for a search.' },
};

const globalDeliveryMessages: Translation = {
    messageDeliveryTypeGeneral: { code: '1-6-6226', text: 'Delivery method changed' },
};

const globalPaymentMessages: Translation = {
    messagePaymentTypeCreditCard: { code: '1-6-6227', text: 'Payment type credit card' },
    messagePaymentTypeInvoice: { code: '1-6-6227', text: 'Payment type invoice' },
    messagePaymentTypeInAdvance: { code: '1-6-6227', text: 'Payment type advance payment' },
    messagePaymentTypeOnDelivery: { code: '1-6-6227', text: 'Payment type cash on delivery' },
    messagePaymentTypeGeneral: { code: '1-6-6227', text: 'Payment type changed' },
};

const globalCompareMessages: Translation = {
    messageHint: { code: '1-6-6240', text: '' },
};

const globalFavoriteMessages: Translation = {
    messageInvalidName: { code: '2-6-6270', text: 'Unable to create the wish list; the name is invalid.' },
    messageMaxLists: { code: '2-6-6277', text: 'Max. number of wish lists reached!' },
    messageMaxPositions: { code: '2-6-6278', text: 'Max. number of positions reached!' },
    messageDeleteConfirmation: { code: '2-6-6273', text: 'Do you really want to delete the wish list?' },
};

const globalAutocompleteMessages: Translation = {
    messageNoValueFound: { code: '1-6-6290', text: 'No match found!' },
};

const globalAccountMessages: Translation = {
    messageDelete: { code: '1-6-6200', text: 'Do you really want to delete this address?' },
    messageChangeSuccess: { code: '1-6-6200', text: 'Address successfully modified.' },
};

const globalGeneralMessages: Translation = {
    messageEmptyText: { code: '1-6-6290', text: 'The output was suppressed due to an empty text.' },
    messageOk: { code: '1-6-6290', text: 'Yes' },
    messageCancel: { code: '1-6-6290', text: 'No' },
    messageChange: { code: '1-6-6290', text: 'The selection was changed' },
    messageDefault: { code: '1-6-0000', text: '' },
    messageDefaultInfo: { code: '1-6-0000', text: '' },
    messageDefaultWarning: { code: '2-6-0000', text: '' },
    messageDefaultError: { code: '3-6-0000', text: '' },
    messageDefaultDebug: { code: '4-6-0000', text: '' },
};

const globalServiceMessages: Translation = {
    messageServiceInfo: { code: '1-6-6969', text: '' },
};

const globalDirectSearchSuggestMessages: Translation = {
    messageTooShort: { code: '1-6-6260', text: 'At least 3 characters!' },
    messageResultEmpty: { code: '1-6-6260', text: 'No items found!' },
};

const globalFileuploadAreaMessages: Translation = {
    defaultMessage: { code: '1-6-2002', text: 'Store the files here to upload them' },
    fallbackMessage: { code: '2-6-2002', text: 'Your browser does not support drag&drop file uploads' },
    fallbackText: { code: '2-6-2002', text: 'Use the form to upload your files' },
    fileTooBig: { code: '2-6-2002', text: 'The file is too large. The maximum file size is {{maxFileSize}} MB' },
    invalidFileType: { code: '2-6-2002', text: 'Unable to upload files of this type' },
    responseError: { code: '3-6-2002', text: 'The server rejected your request with status {{statusCode}}' },
    cancelUpload: { code: '1-6-2002', text: 'Cancel upload' },
    cancelUploadConfirmation: { code: '1-6-2002', text: 'Cancel upload?' },
    removeFile: { code: '1-6-2002', text: 'Remove file' },
    maxFilesExceeded: { code: '2-6-2002', text: 'You are not allowed to upload any more files' },
    buttonBrowse: { code: '1-6-2002', text: 'Select files to upload' },
    uploadHint: { code: '1-6-2002', text: 'Drag files and folders here' },
};

const globalFilterMessages: Translation = {
    emptyResult: { code: '1-1-6280', text: 'Keine Ergebnisse gefunden.' },
};

const globalCheckoutMessages: Translation = {
    missingTerms: { code: '3-6-6230', text: 'Please accept the General terms and conditions.' },
    loadingCheckoutTitle: { code: '1-6-6260', text: 'Almost done!' },
    loadingCheckoutText: { code: '1-6-6261', text: 'The order is carried out. This can take a moment!' },
};

const globalHtmlErrorCodeMessages: Translation = {
    htmlErrorGeneral: { code: '3-6-6000', text: 'Something has gone wrong!' },
    htmlError401: { code: '3-6-6000', text: 'A valid authentication is required to process the request.' },
    htmlError403: { code: '3-6-6000', text: 'The request could not be processed due to missing authorization of the client.' },
    htmlError404: { code: '3-6-6000', text: 'The requested site does not exist.' },
    htmlError413: { code: '3-6-6000', text: 'The file is too large.' },
    htmlError415: { code: '3-6-6000', text: 'This media type is not supported.' },
    htmlError422: { code: '3-6-6000', text: 'Unable to display the requested site correctly.' },
    htmlError500: { code: '3-6-6000', text: 'Internal server error' },
    htmlError503: { code: '3-6-6000', text: 'The server is temporarily unavailable.' },
};

if (!window.opacc) {
    window.opacc = {};
}

export const formErrorMessages = {
    ...globalFormErrorMessages,
    ...globalFormPasswordValidityMessages,
    ...globalFormExpandErrorMessages,
    ...window.opacc.tkFormValidityMessages,
    ...window.opacc.tkFormPasswordValidityMessages,
    ...window.opacc.tkFormExpandErrorMessages,
    ...window.opacc.projectFormValidityMessages,
    ...window.opacc.projectFormPasswordValidityMessages,
    ...window.opacc.projectFormExpandErrorMessages,
};

export const formExpandErrorMessages = {
    ...globalFormExpandErrorMessages,
    ...window.opacc.tkFormExpandErrorMessages,
    ...window.opacc.projectFormExpandErrorMessages,
};

export const basketMessages = {
    ...globalBasketMessages,
    ...window.opacc.tkBasketMessages,
    ...window.opacc.projectBasketMessages,
};

export const compareMessages = {
    ...globalCompareMessages,
    ...window.opacc.tkCompareMessages,
    ...window.opacc.projectCompareMessages,
};

export const favoriteMessages = {
    ...globalFavoriteMessages,
    ...window.opacc.tkFavoriteMessages,
    ...window.opacc.projectFavoriteMessages,
};

export const generalMessages = {
    ...globalGeneralMessages,
    ...window.opacc.tkGeneralMessages,
    ...window.opacc.projectGeneralMessages,
};

export const serviceMessages = {
    ...globalServiceMessages,
    ...window.opacc.tkServiceMessages,
    ...window.opacc.projectServiceMessages,
};

export const directSearchSuggestMessages = {
    ...globalDirectSearchSuggestMessages,
    ...window.opacc.tkDirectSearchSuggestMessages,
    ...window.opacc.projectDirectSearchSuggestMessages,
};

export const fileuploadAreaMessages = {
    ...globalFileuploadAreaMessages,
    ...window.opacc.tkFileUploadAreaMessages,
    ...window.opacc.projectFileUploadAreaMessages,
};

export const filterMessages = {
    ...globalFilterMessages,
    ...window.opacc.tkFilterMessages,
    ...window.opacc.projectFilterMessages,
};

export const autocompleteMessages = {
    ...globalAutocompleteMessages,
    ...window.opacc.tkAutocompleteMessages,
    ...window.opacc.projectAutocompleteMessages,
};

export const paymentMessages = {
    ...globalPaymentMessages,
    ...window.opacc.tkPaymentMessages,
    ...window.opacc.projectPaymentMessages,
};
export const deliveryMessages = {
    ...globalDeliveryMessages,
    ...window.opacc.tkDeliveryMessages,
    ...window.opacc.projectDeliveryMessages,
};
export const accountMessages = {
    ...globalAccountMessages,
    ...window.opacc.tkAccountMessages,
    ...window.opacc.projectAccountMessages,
};
export const checkoutMessages = {
    ...globalCheckoutMessages,
    ...window.opacc.tkCheckoutMessages,
    ...window.opacc.projectCheckoutMessages,
};
export const htmlErrorCodeMessages = {
    ...globalHtmlErrorCodeMessages,
    ...window.opacc.tkHtmlErrorCodeMessages,
    ...window.opacc.projectHtmlErrorCodeMessages,
};