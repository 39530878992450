﻿import render from '@tk/utilities/tk.render';
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import { fetchRequest } from '@tk/utilities/tk.fetch';

interface NextLevelResponse {
    showItems: string
}

interface Page {
    id: string
    element: HTMLElement
}

export default class TKNavigationSidebar extends TKCustomElementFactory {
    navigationWrapper?: HTMLElement;
    wrapper?: HTMLElement;
    openButton?: HTMLElement;
    closeButton?: HTMLElement;
    openClassName: string;
    hasChildrenClassName: string;
    isMobile: boolean;
    isMobileActive: boolean;
    currentMenuId?: string;
    asyncTemplateName: string;
    desktopMinWidth: number;
    overlayClassName: string;
    overlay: HTMLElement;

    desktopView?: string;
    mobileView?: string;

    private pages: Page[] = [];

    constructor() {
        super();

        this.navigationWrapper = document.querySelector('[data-tk-nav-wrapper]') || undefined;
        this.wrapper = this.querySelector('[data-tk-wrapper]') || undefined;
        this.openButton = document.querySelector(`[data-tk-nav-open="${this.id}"]`) || undefined;
        this.closeButton = document.querySelector(`[data-tk-nav-close="${this.id}"]`) || undefined;
        this.openClassName = this.getAttribute('data-tk-open-class-name') || 'viewport__navigation--open';
        this.currentMenuId = this.getAttribute('data-tk-current-menu-id') || undefined;
        this.hasChildrenClassName = (
            this.getAttribute('data-tk-has-children-class-name') || 'tk-nav-burger-item--has-children'
        );
        this.asyncTemplateName = this.getAttribute('data-tk-nav-async-template-name') || 'tk-navigation-burgerlist';
        this.desktopMinWidth = Number(this.getAttribute('data-tk-desktop-min-width')) || 1200;
        this.overlayClassName = this.getAttribute('data-tk-overlay-class-name') || 'viewport__overlay';
        this.isMobile = window.viewport.clientWidth < this.desktopMinWidth;
        this.isMobileActive = false;
        this.overlay = render(`<div class="${this.overlayClassName}"></div>`)!;
    }

    connectedCallback(): void {
        if (!this.currentMenuId) throw new Error('Current menu id is missing!');

        this.registerViewportListener();
        this.registerButtonListener();
        this.registerClickListener();

        this.isMobile && this.switchToMobile();
    }

    registerViewportListener(): void {
        const onViewportResize = this.handleViewportResize.bind(this);
        this.pushListener({ event: 'tk-viewport-resize', element: window.viewport, action: onViewportResize });
    }

    handleViewportResize(event: Event): void {
        const customEvent = event as CustomEvent;
        this.isMobile = customEvent.detail.width < this.desktopMinWidth;
        this.isMobile ? this.switchToMobile() : this.switchToDesktop();
    }

    registerButtonListener(): void {
        this.openButton?.addEventListener('click', this.openNavigation.bind(this));
        this.closeButton?.addEventListener('click', this.closeNavigation.bind(this));
    }

    registerClickListener(): void {
        this.addEventListener('click', this.handleClickLink.bind(this));
    }

    openNavigation(): void {
        this.navigationWrapper
            ? this.navigationWrapper.classList.add(this.openClassName)
            : this.classList.add(this.openClassName);
        document.body.insertAdjacentElement('beforeend', this.overlay);
    }

    closeNavigation(): void {
        this.navigationWrapper
            ? this.navigationWrapper.classList.remove(this.openClassName)
            : this.classList.remove(this.openClassName);
        const overlay = document.querySelector(`.${this.overlayClassName}`);
        overlay?.remove();
    }

    async handleClickLink(event: Event): Promise<void> {
        if (!this.isMobile) return;
        event.preventDefault();

        const target = event.target as HTMLAnchorElement;
        let element: HTMLAnchorElement | null;
        if (target.hasAttribute('data-tk-nav-id')) {
            element = target;
        } else {
            element = target.closest('[data-tk-nav-id]');
        }

        if (!element) return;
        if (
            !element.parentElement?.classList.contains(this.hasChildrenClassName)
            && !element.parentElement?.hasAttribute('data-tk-nav-page')
        ) {
            window.location.href = element.href;
            this.closeNavigation();
            return;
        }
        const id = element.getAttribute('data-tk-nav-id');

        if (!id) return;
        if (this.existPage(id)) {
            const page = this.getPage(id);
            if (!page) return;
            this.insertPage(page.element);
            TKNavigationSidebar.jumpToPage(page.element);
            this.removeLastPageFromDOM();
        } else {
            const response = await this.getTemplate(id);
            this.responseNextLevel(response);
        }
    }

    getTemplate(id: string) {
        const url = window.opacc.systemUrls?.async || '/WebPortal/async.aspx';
        const data = {
            type: 'navigation',
            navigationid: id,
            template: this.asyncTemplateName,
        };

        return new Promise<TKResponse<NextLevelResponse>>((resolve, reject) => {
            fetchRequest({
                requestURL: url,
                resolveHandler: (response: TKResponse<NextLevelResponse>) => resolve(response),
                rejectHandler: (error: unknown) => reject(error),
                payload: data,
            });
        });
    }

    responseNextLevel(response: TKResponse<NextLevelResponse>) {
        if (!response.success) return;
        const html = render(response.dataAsJson.showItems);
        if (!html) return;
        const navigation = html.querySelector('[data-tk-nav-page]');
        if (!navigation) return;
        this.addPage(navigation);
        this.insertPage(navigation);
        TKNavigationSidebar.jumpToPage(navigation);
        this.removeLastPageFromDOM();
    }

    insertPage(element: Element) {
        const pages = this.querySelector('[data-tk-nav-pages]');
        if (!pages) return;
        pages.insertAdjacentElement('afterbegin', element);
    }

    addPage(element: Element) {
        const id = element.getAttribute('data-tk-nav-page') || '';
        !this.existPage(id) && this.pages.push({ id, element } as Page);
    }

    getPage(id: string) {
        return this.pages.find((page) => page.id === id);
    }

    existPage(id: string) {
        if (id === '') return false;
        const foundPage = this.pages.filter((page) => page.id === id);
        return foundPage.length > 0;
    }

    removeLastPageFromDOM() {
        const pages = this.querySelector('[data-tk-nav-pages]');
        if (!pages) return;
        const pageList = Array.from(pages.children);
        const oldPages = pageList.filter((element) => element !== pages.firstElementChild);
        oldPages.forEach((element) => element.remove());
    }

    async switchToMobile() {
        if (this.isMobileActive) return;
        this.isMobileActive = true;
        this.closeButton && (this.closeButton.hidden = false);
        if (!this.wrapper) return;
        this.desktopView = this.wrapper.innerHTML;

        if (!this.mobileView) {
            const response = await this.getTemplate(this.currentMenuId!);
            const html = render(response.dataAsJson.showItems);
            if (!html) throw new Error('Burgerlist: Root Element is missing!');
            this.wrapper.replaceChildren(html);
            const navigation = html.querySelector('[data-tk-nav-page]');
            navigation && this.addPage(navigation);
        } else {
            this.wrapper.innerHTML = '';
            this.wrapper.insertAdjacentHTML('afterbegin', this.mobileView);
        }
    }

    async switchToDesktop() {
        if (!this.isMobileActive) return;
        this.isMobileActive = false;
        this.closeButton && (this.closeButton.hidden = true);
        if (!this.wrapper) return;
        this.mobileView = this.wrapper.innerHTML;
        if (!this.desktopView) return;
        this.wrapper.innerHTML = '';
        this.wrapper.insertAdjacentHTML('afterbegin', this.desktopView);
    }

    static jumpToPage(element: Element) {
        element.scrollIntoView(false);
    }
}