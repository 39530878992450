﻿import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class TKHeader extends TKCustomElementFactory {
    header?: HTMLElement;
    disableScrolldownBreakpoint?: Breakpoint;
    minWidthBreakpoint: number;
    elementToScrollWith?: HTMLElement;

    private lastScrollPosY: number = 0;
    private maxHeightScrollWith: number;
    private scrollWay: number = 0;
    private scrollDeltaY: number;

    constructor() {
        super();

        this.header = this.querySelector('header') || undefined;
        if (!this.header) throw new Error('Header: Header is missing!');
        this.disableScrolldownBreakpoint = (
            this.getAttribute('data-tk-disable-scrolldown-breakpoint') as Breakpoint
        ) || 's';
        this.minWidthBreakpoint = window.viewport.breakpoints.get(this.disableScrolldownBreakpoint) || 0;
        this.elementToScrollWith = this.querySelector('[data-tk-to-scroll-with]') || undefined;
        if (!this.elementToScrollWith) {
            throw new Error('Header: Element to scroll with is missing! Use the [data-tk-to-scroll-with] selector.');
        }

        this.maxHeightScrollWith = this.getMaxHeight();
        this.scrollDeltaY = Number(this.getAttribute('data-tk-scroll-delta-y')) || 1;
    }

    connectedCallback(): void {
        this.registerViewportListener();
        window.viewport.clientWidth < this.minWidthBreakpoint && this.registerScrollListener();
    }

    registerScrollListener(): void {
        if (!this.elementToScrollWith) return;
        const onScrolldown = this.handleScrolldown.bind(this);
        this.maxHeightScrollWith = this.getMaxHeight();
        this.pushListener({
            event: 'scroll',
            element: window,
            action: onScrolldown,
        });
    }

    registerViewportListener(): void {
        const onViewportResize = this.handleViewportResize.bind(this);
        this.pushListener({
            event: 'tk-viewport-resize',
            element: window.viewport,
            action: onViewportResize,
        });
    }

    handleViewportResize(event: Event): void {
        const customEvent = event as CustomEvent;
        if (customEvent.detail.width < this.minWidthBreakpoint) {
            this.registerScrollListener();
        } else {
            this.removeListener(window);
        }
    }

    handleScrolldown(event: Event): void {
        if (!(this.header && this.elementToScrollWith)) return;
        const { scrollY } = event.currentTarget as Window;

        if (scrollY > this.lastScrollPosY) {
            if (scrollY <= this.maxHeightScrollWith) {
                this.scrollWay = scrollY;
            } else {
                this.scrollWay = this.maxHeightScrollWith;
            }
        } else if (this.scrollWay > this.scrollDeltaY) {
            this.scrollWay = this.lastScrollPosY - scrollY >= this.maxHeightScrollWith
                ? 0
                : this.lastScrollPosY - scrollY;
        } else {
            this.scrollWay = 0;
        }

        this.elementToScrollWith.style.transform = `translateY(-${this.scrollWay}px)`;
        this.lastScrollPosY = scrollY;
    }

    getMaxHeight(): number {
        if (!this.elementToScrollWith) return 0;
        const { paddingBottom } = window.getComputedStyle(this.elementToScrollWith);
        const { height } = this.elementToScrollWith.getBoundingClientRect();
        return height - parseInt(paddingBottom, 10);
    }
}