﻿/**
 * @param {string} html representing any number of sibling elements
 * @return {NodeListOf<ChildNode>}
 */
const render = <T= HTMLElement>(html: string, isBody = false): T => {
    if (!html) render('<div></div>');
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return isBody
        ? doc.body as T
        : doc.body.children.item(0) as T;
};

export default render;